import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { path } from 'ramda';
import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import { TextField } from 'src/common/components/text-field/text-field';
import { Text } from 'src/common/components/text/text';
import { Toggle } from 'src/common/components/toggle/toggle';
import { DomainPrivacy, PrivacyVisibility } from 'src/common/types/privacy';
import { AvailablePlan } from 'src/pricing/types';
import { calculateAllowedDomainErrorMessage } from 'src/settings/utils/calculate-allowed-domain-error-message';
import { calculateAllowedDomainHintMessage } from 'src/settings/utils/calculate-allowed-domain-hint-message';
import { isAllowedDomainValid } from 'src/settings/utils/is-allowed-domain-valid';

import { ContentSection } from '../content-section/content-section';
import { DomainRestrictionsInsufficientPlanUpsell } from '../domain-restrictions-insufficient-plan-upsell/domain-restrictions-insufficient-plan-upsell';
import { PrivacyAndSecurityPassword } from '../privacy-and-security-password/privacy-and-security-password';
import { PrivacyAndSecurityLayout } from '../privacy-and-security/privacy-and-security-component';
import { SettingOption } from '../setting-option/setting-option';
import { UpsellLabel } from '../upsell-label/upsell-label';

type PrivacyAndSecurityRestrictionsProps = {
  domainRestrictions: DomainPrivacy;
  allowedDomain: string | null;
  currentPlan: ActivatedSubscriptionInfo | null;
  proPlan?: AvailablePlan;
  hasAllowedDomainSaved: boolean;
  isAllowedDomainSaving: boolean;
  isPasswordPresent: boolean;
  layout: PrivacyAndSecurityLayout;
  visibility: PrivacyVisibility;
  handleAllowedDomainChange: (value: string) => void;
  handleUpsellClick: (source: string) => void;
  handleDomainRestrictionsToggle: () => void;
  handlePasswordSubmit?: (password: string) => void;
  handleTurnOffPasswordProtection?: () => void;
  openChangeCurrentPlanModal: (planId: string) => void;
};

export const PrivacyAndSecurityRestrictions = ({
  domainRestrictions,
  allowedDomain: allowedDomainProp,
  currentPlan,
  proPlan,
  hasAllowedDomainSaved,
  isPasswordPresent,
  isAllowedDomainSaving,
  layout = 'generic',
  visibility,
  handleAllowedDomainChange: handleAllowedDomainChangeProp,
  handleTurnOffPasswordProtection,
  handlePasswordSubmit,
  handleDomainRestrictionsToggle,
  handleUpsellClick,
  openChangeCurrentPlanModal,
}: PrivacyAndSecurityRestrictionsProps) => {
  const isPrivate = visibility === 'private';
  const isVideoLayout = layout === 'video';
  const isOnInsufficientPlan = currentPlan
    ? ['Basic', 'Elements'].includes(currentPlan.name)
    : false;
  const noPlanOrInsufficientPlanInVideoLayout =
    !currentPlan || (isVideoLayout && isOnInsufficientPlan);
  const canSetDomainRestrictions = !noPlanOrInsufficientPlanInVideoLayout;

  const [allowedDomain, setAllowedDomain] = useState(allowedDomainProp);
  const [isValid, setIsValid] = useState(true);

  const handleDomainPrivacyChange = () => {
    if (noPlanOrInsufficientPlanInVideoLayout) {
      if (currentPlan) {
        const planId =
          path<string>([currentPlan.cadence, 'id'], proPlan) || null;
        if (!isEmpty(planId)) {
          openChangeCurrentPlanModal(planId as string);
        }
      } else {
        handleUpsellClick('domain-privacy');
      }
    } else {
      handleDomainRestrictionsToggle();
    }
  };

  const handleAllowedDomainChange = (value: string) => {
    setAllowedDomain(value);

    const isValid = isAllowedDomainValid(value);
    setIsValid(isValid);

    if (isValid) {
      handleAllowedDomainChangeProp(value);
    }
  };

  return (
    <ContentSection title="Restrictions">
      <SettingOption
        label={
          <UpsellLabel
            enableUpsell={noPlanOrInsufficientPlanInVideoLayout}
            label="Domain Privacy"
            planName={isVideoLayout ? 'Pro' : 'Basic'}
          />
        }
        secondaryLabel={
          <Text variant="secondary">
            Only embeddable on domains you specify.
          </Text>
        }
        withSeparator={
          canSetDomainRestrictions || domainRestrictions !== 'allowlist'
        }
        onClick={handleDomainPrivacyChange}
        disabled={!isOnInsufficientPlan && isPrivate}
      >
        <Toggle
          name="domain_privacy"
          checked={
            canSetDomainRestrictions && domainRestrictions === 'allowlist'
          }
          size="large"
          disabled={noPlanOrInsufficientPlanInVideoLayout || isPrivate}
        />
      </SettingOption>

      {domainRestrictions === 'allowlist' &&
        (isVideoLayout && isOnInsufficientPlan ? (
          <DomainRestrictionsInsufficientPlanUpsell
            currentPlan={currentPlan!}
            proPlan={proPlan}
            openChangeCurrentPlanModal={openChangeCurrentPlanModal}
          />
        ) : (
          <div className="privacy-and-settings__restrictions__text-field-container">
            <TextField
              label="Allowed domains"
              placeholder="example.com"
              hint={calculateAllowedDomainHintMessage({
                hasSaved: hasAllowedDomainSaved,
                isSaving: isAllowedDomainSaving,
                isValid,
              })}
              onChange={handleAllowedDomainChange}
              value={allowedDomain || ''}
              disabled={!currentPlan || isPrivate}
              errorMessage={calculateAllowedDomainErrorMessage(isValid)}
            />
          </div>
        ))}

      {isVideoLayout && (
        <PrivacyAndSecurityPassword
          disabled={!currentPlan}
          isPasswordPresent={isPasswordPresent}
          handlePasswordSubmit={handlePasswordSubmit}
          handleUpsellClick={() => handleUpsellClick('password-protection')}
          handleTurnOffPasswordProtection={() => {
            if (currentPlan && handleTurnOffPasswordProtection) {
              handleTurnOffPasswordProtection();
            }
          }}
        />
      )}
    </ContentSection>
  );
};
